import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import CarouselEg from "../components/carousel"
import ChooseUs from "../components/choose-us"
// import Services from "../components/services"
import TopBanner from "../components/topbanner"
import HowItWorks from "../components/how-it-works"
import { Container, Row,Col } from "reactstrap"
import ContactForm from "../components/contact"
import FreeOffer from "../components/freeoffer"
import WhatWeDO from "../components/wedo"
import ExampleTabs from "../components/egtabs"
// import Services from "../components/services"
import ServicesOffered from "../components/our-services"
import Testimonial from "../components/testimonial"

const IndexPage = () => (
  <Layout>
    <SEO 
      title="Repair Mobile Phones, Laptops, Desktops, Tablets Online at Doorstep"
      keywords={[`mobile repair shop near me`, `mobile repair`, `online mobile repair`, `mobile repair shops near me`, `mobile repair near me`, `mobile repair online`, `mobile repair at home`, `mobile repair shop`, `mobile repair store near me`	, `doorstep mobile repair`,
      `laptop repair near me`, `laptop repair`, `laptop repair shop near me`,  `laptop repair shops near me`, `laptop repair centre near me`, `laptop repair center near me`,
      `cctv camera`, `cctv`, `cctv camera price`, `cctv camera installation`, `cctv surveillance`, `cctv installation`,`ipad repair`,`iphone repair`,`iphone screen replacement`,`Mobile phone screen replacement`,
      `Desktop service`,`doorstep service`,`doorstep laptop repair`]}
    />
    <WhatWeDO/>
    <ChooseUs/>
    <FreeOffer/>
    <div className="p-5" id="services">
      <Container>
        <ServicesOffered/>
      </Container>
    </div>
    <hr/>
    <div className="p-5 parallax-works" id="how-works">
      <Container>
        <HowItWorks/>
      </Container>
    </div>
    <hr/>
    <Testimonial/>
    <div id="contact" style={{backgroundColor:"#e9ecef"}}>
      <Container className="p-5">
        <ContactForm/>
      </Container>
    </div>
  </Layout>
)

export default IndexPage
